import styled from "styled-components";
import { HalfWH } from "../mixins-modules"; 
import { breakpoints } from "@/shared/Constants";
export const ContentHalfNoImage = styled.div`
 ${HalfWH}
  .mt{
    h2.title, .volanta{
      font-size: 21px;
      line-height: 24px;
      margin-bottom: 5px;
      ${breakpoints.phone} {
          font-size: 22px;
          line-height: 25px;
      }
    }
    
  }
`;
